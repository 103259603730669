const ru = {
  title: 'Настройки',
  selectTitle: 'Звание',
  usernameTxt: 'Имя пользователя',
  email: 'Электронная почта',
  visible: 'Видно:',
  everyone: 'Всем',
  onlyYou: 'Только мне',
  linkedIn: 'LinkedIn профиль',
  saveBtn: 'Сохранить',
  cancelBtn: 'Отмена',
  usernameValidationMessage:
    'Разрешено 6-30 символов, без специальных символов.',
  invalidLinkedIn: 'Не верный формат ссылки',
};

export default ru;
