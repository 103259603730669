const en = {
  shortTitle: '(uxCAT)',
  title: 'Awareness Test',
  guestUsername: 'Guest User',
  accordionTitle: 'Rules',
  description: `This is the only credible online test that will let you to evaluate your awareness and thinking patterns (cognitive biases) spotting skills. 
Pass tests, earn points, level up, unlock unique achievements, and do all this while becoming smarter and having fun.
The test is free, as everything around here, always.`,
  awarenessPointsTxt: 'Awareness Points: Pass the test to earn points',
  startBtn: 'Begin Test',
  myProfileTxt: 'Show My Profile',
  lvlProgression: 'Level Progression',
  myLevelProgression: 'My Level Progression',
  achievementTxt: 'Achievements',
  showAchievementsTxt: 'Show all achievements',
  yourPointsTxt: 'Your awareness points:',
  yourPointsUserPage: 'Awareness points:',
  nextTestIn: 'Next Test In',
  minutesTxtShort: 'Min',
  hoursTxtShort: 'Hours',
  secondsTxtShort: 'Sec',
  continueTest: 'Continue Test',
  finalTestTxt: 'Final Test',
  achievedOnlyTxt: 'Achieved Only',
  allTxt: 'All',
  showAchievementDropdown: 'Show',
  zeroLevelUser: 'Guest',
  hiddenAchievement: 'Hidden',
  locked: 'Not unlocked yet',
  unlockedTxt: 'Unlocked',
  hiddenTooltip:
    'Achievement criteria unknown. Pass more tests, and eventually you should get it.',
  general: 'General',
  special: 'Special',
  usersHaveThis: '% users have this',
  lvl: 'Level',
  lvlShort: 'LVL',
  top: 'Top',
  rank: 'Rank',
  unlockAt: 'Unlock at level 2',
  nextUpdateIn: 'Next update in',
};

export default en;
